import { FormikProps } from 'formik'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Switch } from '../../components/common/Switch'
import { Flex } from '../../components/FlexBox'
import DismissIcon from '../../components/icon/DismissIcon'
import { ReorderSectionControls } from './ReorderControls'
import { IEditorState, ISectionState } from './types'
import { Text } from '../../components/common/Text'
import useTranslation from '../../hooks/useTranslation'
import useFeatureFlag from '../../hooks/useFeatureFlag'

interface IProps {
  form: FormikProps<IEditorState>
  sectionIndex: number
  section: ISectionState
}

export const SectionControls: React.FC<IProps> = ({
  section,
  form,
  sectionIndex
}) => {
  const { values, setValues } = form
  const { t } = useTranslation()
  const isAgendaPdfEnabled = useFeatureFlag('createEventAgendaPDF')
  const showInApp = isAgendaPdfEnabled ? section.uid !== 'pageBreak' : true

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      style={{ padding: 8 }}
    >
      {sectionIndex !== 0 && (
        <TouchableOpacity
          onPress={() => {
            values.sections.splice(sectionIndex, 1)
            form.setFieldTouched(`sections.${sectionIndex}`, false)
            setValues(values)
          }}
          // @ts-expect-error
          style={{ cursor: 'pointer' }}
        >
          <DismissIcon />
        </TouchableOpacity>
      )}

      <Flex flexDirection="row" alignItems="center">
        <ReorderSectionControls
          sections={values.sections}
          sectionIndex={sectionIndex}
          form={form}
        />

        {sectionIndex !== 0 && showInApp ? (
          <>
            <Text>{t('summits:editor:showInApp')}</Text>
            <Switch
              value={section.live}
              onValueChange={value => {
                // Update the live status of the section
                form.setFieldValue(`sections.${sectionIndex}.live`, value)
                // Iterate through each block in the section and update its live status to match the section's
                section.blocks.forEach((block, blockIndex) => {
                  form.setFieldValue(
                    `sections.${sectionIndex}.blocks.${blockIndex}.live`,
                    value
                  )
                })
              }}
            />
          </>
        ) : null}
        {isAgendaPdfEnabled && section.uid !== 'pageBreak' ? (
          <>
            <Text>{t('summits:editor:showInPdf')}</Text>
            <Switch
              value={section.exportToPdf}
              onValueChange={value => {
                form.setFieldValue(
                  `sections.${sectionIndex}.exportToPdf`,
                  value
                )
              }}
            />
          </>
        ) : null}
      </Flex>
    </Flex>
  )
}
