import React, { FC } from 'react'
import styled from 'styled-components/native'
import { IQueue } from './queries/queue'
import { Text } from '../../components/common/Text'
import { TouchableOpacity } from 'react-native'
import {
  ILastExtractionTime,
  useExtractionTimes
} from './queries/lastExtraction'
import { extractionQueues } from './queries/runExtraction'

export const TableContainer = styled.View`
  flex: 1;
  padding: 10px;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 8px;
`

export const TableRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  background-color: ${props => props.theme.colors.white};
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.colors.lightGrey};
  align-items: center;
`

export const TableHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  background-color: ${props => props.theme.colors.white};
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.colors.lightGrey};
`

export const TableCol = styled.View`
  flex: 1;
  min-width: 100px;
`

const AmountText = styled(Text)`
  font-weight: ${props =>
    props.children.toString() === '0' ? 'normal' : 'bold'};
  color: ${props =>
    props.children.toString() === '0' ? '#999' : props.theme.colors.headerText};
`

const getExtractionTime = (
  queueName: string,
  extractionTimes?: ILastExtractionTime[]
) => {
  const date = extractionTimes?.find(
    extractionTime =>
      extractionTime.extractionType === extractionQueues[queueName]?.type
  )?.salesforceTimestamp
  if (!date) {
    return 'Never'
  }
  return new Date(date).toLocaleString()
}

interface QueueTableProps {
  queues?: IQueue[]
  filter: Parameters<IQueue[]['filter']>['0']
  setSelectedQueue: (queue: string) => void
  selectedQueue?: string
  showExtraColumns?: boolean
}

export const QueueTable: FC<QueueTableProps> = ({
  queues,
  filter,
  setSelectedQueue,
  selectedQueue,
  showExtraColumns
}) => {
  const { extractionTimes } = useExtractionTimes()

  return (
    <TableContainer>
      <TableHeader>
        <TableCol style={{ minWidth: 300 }}>
          <Text>Queue</Text>
        </TableCol>
        <TableCol>
          <Text>Completed</Text>
        </TableCol>
        <TableCol>
          <Text>Failed</Text>
        </TableCol>
        <TableCol>
          <Text>Active</Text>
        </TableCol>
        <TableCol>
          <Text>Waiting</Text>
        </TableCol>
        <TableCol>
          <Text>Last Extraction Time</Text>
        </TableCol>
        {showExtraColumns && (
          <>
            <TableCol>
              <Text>Listeners</Text>
            </TableCol>
            <TableCol>
              <Text>Dependencies</Text>
            </TableCol>
          </>
        )}
      </TableHeader>
      {queues?.filter(filter).map(queue => (
        <TouchableOpacity
          onPress={() => {
            setSelectedQueue(queue.name)
          }}
          key={queue.name}
        >
          <TableRow
            style={{
              backgroundColor:
                selectedQueue === queue.name ? '#d7eaff' : undefined
            }}
          >
            <TableCol style={{ minWidth: 300 }}>
              <Text
                key={queue.name}
                styles={{
                  color: extractionQueues[queue.name]?.endpoint
                    ? 'black'
                    : 'grey'
                }}
              >
                {extractionQueues[queue.name]?.name ?? queue.name}{' '}
              </Text>
            </TableCol>
            <TableCol>
              <AmountText>{queue.stats.completed.toString()}</AmountText>
            </TableCol>
            <TableCol>
              <AmountText>{queue.stats.failed.toString()}</AmountText>
            </TableCol>
            <TableCol>
              <AmountText>{queue.stats.active.toString()}</AmountText>
            </TableCol>
            <TableCol>
              <AmountText>{queue.stats.waiting.toString()}</AmountText>
            </TableCol>
            <TableCol>
              <Text>{getExtractionTime(queue.name, extractionTimes)}</Text>
            </TableCol>
            {showExtraColumns && (
              <>
                <TableCol>
                  <AmountText>{queue.graph?.listeners?.length || 0}</AmountText>
                </TableCol>
                <TableCol>
                  <AmountText>
                    {queue.graph?.dependencies?.length || 0}
                  </AmountText>
                </TableCol>
              </>
            )}
          </TableRow>
        </TouchableOpacity>
      ))}
    </TableContainer>
  )
}
