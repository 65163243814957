import Constants from 'expo-constants'

const baseUrl = Constants.manifest?.extra?.DATA_EXTRACTION_URL

export interface IExtractionQueue {
  endpoint?: string
  name: string
  type?: string
  specificExtractions?: {
    description: string
    buildContext: (value: string) => Record<string, unknown>
  }[]
}

export const extractionQueues: Record<string, IExtractionQueue> = {
  'mq:email:monthly': {
    name: 'Monthly Email'
  },
  'mq:extraction:connections': {
    endpoint: '/v2/connections',
    name: 'Connections',
    type: 'connections'
  },
  'mq:extraction:activePeople': {
    endpoint: '/v2/activePeople',
    name: 'Active People',
    type: 'activePeople'
  },
  'mq:extraction:inactivePeople': {
    endpoint: '/v2/inactivePeople',
    name: 'Inactive People',
    type: 'inactivePeople'
  },
  'mq:extraction:media:vimeo': {
    endpoint: '/v2/media-vimeo',
    name: 'Vimeo',
    type: 'vimeo',
    specificExtractions: [
      {
        description: 'Enter a comma separated list of video IDs',
        buildContext: (videoIds: string) => ({
          ids: videoIds.split(',').map(id => id.trim())
        })
      }
    ]
  },
  'mq:extraction:groups': {
    endpoint: '/v2/groups',
    name: 'Groups',
    type: 'groups',
    specificExtractions: [
      {
        description: 'Enter a comma separated list of group IDs',
        buildContext: (groupIds: string) => ({
          groupSFIDs: groupIds.split(',').map(id => id.trim())
        })
      }
    ]
  },
  'mq:extraction:groupMembership': {
    endpoint: '/v2/group-memberships',
    name: 'Group Membership',
    type: 'groupMembership',
    specificExtractions: [
      {
        description: 'Enter a comma separated list of group short names',
        buildContext: (groupIds: string) => ({
          shortNames: groupIds.split(',').map(id => id.trim())
        })
      }
    ]
  },
  'mq:extraction:groupLeadership': {
    endpoint: '/v2/group-leadership',
    name: 'Group Leadership',
    type: 'groupLeadership'
  },
  'mq:extraction:people': {
    endpoint: '/v2/people',
    name: 'People',
    type: 'people',
    specificExtractions: [
      {
        description: "Enter a comma separated list of people's Salesforce IDs",
        buildContext: (peopleIds: string) => ({
          peopleSFIDs: peopleIds.split(',').map(id => id.trim())
        })
      }
    ]
  },
  'mq:extraction:company': {
    endpoint: '/v2/company',
    name: 'Company',
    type: 'company'
  },
  'mq:calendar:attendees': {
    name: 'Calendar Attendees'
  },
  'mq:calendar:event': {
    name: 'Calendar Events'
  },
  'mq:extraction:media:cloudinary': {
    name: 'Cloudinary',
    type: 'cloudinary'
  },
  'mq:extraction:events': {
    name: 'Events',
    type: 'events',
    endpoint: '/v2/events',
    specificExtractions: [
      {
        description: "Enter a comma separated list of event's Salesforce IDs",
        buildContext: (eventIds: string) => ({
          eventIds: eventIds.split(',').map(id => id.trim())
        })
      }
    ]
  },
  'mq:extraction:eventAttendees': {
    name: 'Event Attendees',
    type: 'eventAttendees'
  },
  'mq:extraction:legacy:library': {
    endpoint: '/v2/library',
    name: 'Library (Legacy)',
    type: 'library'
  },
  'mq:extraction:legacy:g100emails': {
    endpoint: '/v2/g100emails',
    name: 'G100 Emails (Legacy)',
    type: 'g100emails'
  },
  'mq:extraction:legacy:g100contact': {
    endpoint: '/v2/g100contact',
    name: 'G100 Contact (Legacy)',
    type: 'g100contact'
  },
  'mq:extraction:legacy:g100Documents': {
    endpoint: '/v2/g100documents',
    name: 'G100 Documents (Legacy)',
    type: 'g100Documents'
  },
  'mq:extraction:legacy:g100Prospect': {
    endpoint: '/v2/g100prospect',
    name: 'G100 Prospect (Legacy)',
    type: 'g100Prospect'
  },
  'mq:extraction:legacy:jobFunctions': {
    endpoint: '/v2/job-functions',
    name: 'Job Functions (Legacy)',
    type: 'jobFunctions'
  },
  'mq:extraction:legacy:industries': {
    endpoint: '/v2/industries',
    name: 'Industries (Legacy)',
    type: 'industries'
  },
  'mq:extraction:legacy:groupMembershipCleanup': {
    endpoint: '/v2/group-membership-cleanup',
    name: 'Group Membership Cleanup (Legacy)',
    type: 'groupMembershipCleanup'
  },
  'mq:extraction:legacy:ehirUsers': {
    endpoint: '/v2/ehir-users',
    name: 'EHIR Users (Legacy)',
    type: 'ehirUsers',
    specificExtractions: [
      {
        description: 'Enter a comma separated list of user IDs',
        buildContext: (userIds: string) => ({
          ids: userIds.split(',').map(id => parseInt(id.trim(), 10))
        })
      }
    ]
  },
  'mq:extraction:legacy:ehirCompany': {
    endpoint: '/v2/ehir-company',
    name: 'EHIR Company (Legacy)',
    type: 'ehirCompany',
    specificExtractions: [
      {
        description: 'Enter a comma separated list of company IDs',
        buildContext: (companyIds: string) => ({
          ids: companyIds.split(',').map(id => parseInt(id.trim(), 10))
        })
      }
    ]
  },
  'mq:extraction:legacy:ehirCFS': {
    endpoint: '/v2/ehir-cfs',
    name: 'EHIR CFS (Legacy)',
    type: 'ehirCFS'
  },
  'mq:extraction:legacy:ehirSubmissions': {
    endpoint: '/v2/ehir-submissions',
    name: 'EHIR Submissions (Legacy)',
    type: 'ehirSubmissions',
    specificExtractions: [
      {
        description: 'Enter a comma separated list of submission IDs',
        buildContext: (submissionIds: string) => ({
          ids: submissionIds.split(',').map(id => parseInt(id.trim(), 10))
        })
      }
    ]
  },
  'mq:extraction:legacy:ehirCategory': {
    endpoint: '/v2/ehir-category',
    name: 'EHIR Category (Legacy)',
    type: 'ehirCategory'
  },
  'mq:extraction:legacy:ehirProducts': {
    endpoint: '/v2/ehir-products',
    name: 'EHIR Products (Legacy)',
    type: 'ehirProducts',
    specificExtractions: [
      {
        description: 'Enter a comma separated list of product IDs',
        buildContext: (productIds: string) => ({
          ids: productIds.split(',').map(id => parseInt(id.trim(), 10))
        })
      }
    ]
  },
  'mq:extraction:legacy:zoomParticipants': {
    endpoint: '/v2/zoom-participants',
    name: 'Zoom Participants (Legacy)',
    type: 'zoomParticipants'
  },
  'mq:extraction:legacy:contentTopics': {
    endpoint: '/v2/content-topics',
    name: 'Content Topics (Legacy)',
    type: 'contentTopics'
  },
  'mq:extraction:legacy:galleries': {
    endpoint: '/v2/galleries',
    name: 'Galleries (Legacy)',
    type: 'galleries',
    specificExtractions: [
      {
        description: 'Enter a comma separated list of image IDs',
        buildContext: (galleryIds: string) => ({
          ids: galleryIds.split(',').map(id => parseInt(id.trim(), 10))
        })
      }
    ]
  },
  'mq:extraction:legacy:mediaSession': {
    endpoint: '/v2/media-session',
    name: 'Media Session (Legacy)',
    type: 'mediaSession'
  },
  'mq:extraction:legacy:libraryCleanup': {
    endpoint: '/v2/library-cleanup',
    name: 'Library Cleanup (Legacy)',
    type: 'libraryCleanup'
  },
  'mq:extraction:legacy:innovationFileModeration': {
    endpoint: '/v2/innovation-file-moderation',
    name: 'Innovation File Moderation (Legacy)',
    type: 'innovationFileModeration'
  },
  'mq:extraction:legacy:mi6FileModeration': {
    endpoint: '/v2/mi6-cloudinary-file-status',
    name: 'MI6 File Moderation (Legacy)',
    type: 'mi6FileModeration'
  },
  'mq:extraction:legacy:innovationCloudinaryFileModeration': {
    endpoint: '/v2/innovation-cloudinary-file-status',
    name: 'Innovation Cloudinary File Moderation (Legacy)',
    type: 'innovationCloudinaryFileModeration'
  },
  'mq:extraction:legacy:mediaCloudinaryByIds': {
    endpoint: '/v2/media-cloudinary-ids',
    name: 'Media Cloudinary By ids (Legacy)',
    type: 'mediaCloudinaryByIds',
    specificExtractions: [
      {
        description: 'Enter a comma separated list of media IDs',
        buildContext: (mediaIds: string) => ({
          ids: mediaIds.split(',').map(id => id.trim())
        })
      }
    ]
  },
  'mq:extraction:graphGroup': {
    endpoint: '/v2/neo4j/groups',
    name: 'Groups transform (Neo4j)',
    type: 'groups'
  },
  'mq:extraction:graphMembership': {
    endpoint: '/v2/neo4j/memberships',
    name: 'Membership transform (Neo4j)',
    type: 'members'
  }
}

export async function runExtractor(
  queueName: string,
  context?: Record<string, unknown>
) {
  if (!extractionQueues[queueName]) {
    throw new Error(`Invalid queue name: ${queueName}`)
  }
  const url = `${baseUrl}${extractionQueues[queueName].endpoint}`

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body:
      Object.keys(context || {}).length > 0
        ? JSON.stringify(context)
        : undefined
  })
}
