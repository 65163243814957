import React, { useEffect, useMemo, useState } from 'react'
import Modal from '../../../components/common/Modal'
import { Flex } from '../../../components/FlexBox'
import { H4 } from '../../../components/common/Text'
import TextInput from '../../../ui-library/TextInput'
import styled from 'styled-components/native'
import { View, Text } from 'react-native'
import Button from '../../../components/common/Button'
import { CommunitiesMicrositePermission } from '../hooks/useCommunitiesMicrositesPermissions'
import useToast, { ToastType } from '../../../hooks/useToast'
import DateTimeInput from '../../../ui-library/DateTimeInput'
import Dropdown, { DropdownOption } from '../../../components/common/Dropdown'
import useGetCommunities from '../hooks/useGetCommunities'
import { getDefaultDate, convertToZondedTime } from '../utils/dateHelpers'

interface NewPermissionModalProps {
  isOn: boolean
  turnOff: () => void
  permission?: CommunitiesMicrositePermission
  upsertPermission: (values: CommunitiesMicrositePermission) => void
  loading?: boolean
}

const TitleContainer = styled(View)`
  ${({ theme: { space } }) => `
  margin-bottom: ${space[3]}px;
`}
`

const TextInputTitle = styled(Text)`
  ${({ theme: { space } }) => `
    font-size: 14px;
    margin-bottom: ${space[1]}px;
    color: #21201B;
  `}
`

const Field = ({ label, placeholder, value, onChange, ...rest }) => (
  <>
    <TextInputTitle>{label}</TextInputTitle>
    <TextInput
      value={value}
      onChangeText={onChange}
      placeholder={placeholder}
      style={{
        width: '100%'
      }}
      {...rest}
    />
  </>
)

const NewPermissionModal = ({
  isOn,
  turnOff,
  permission,
  upsertPermission,
  loading
}: NewPermissionModalProps) => {
  const {
    data: communities = [],
    loading: loadingCommunities
  } = useGetCommunities()
  const [emailAddress, setEmailAddress] = useState<string | undefined>()
  useEffect(() => {
    if (permission?.emailAddress) {
      setEmailAddress(permission?.emailAddress)
    }
  }, [permission?.emailAddress])

  const [firstName, setFirstName] = useState<string | undefined>()
  useEffect(() => {
    if (permission?.firstName) {
      setFirstName(permission?.firstName)
    }
  }, [permission?.firstName])
  const [lastName, setLastName] = useState<string | undefined>()
  useEffect(() => {
    if (permission?.lastName) {
      setLastName(permission?.lastName)
    }
  }, [permission?.lastName])

  const [communityId, setCommunityId] = useState<string | undefined>()
  useEffect(() => {
    if (permission?.communityId) {
      setCommunityId(permission?.communityId)
    }
  }, [permission?.communityId])
  const [expirationDate, setExpirationDate] = useState<Date | undefined>(
    getDefaultDate()
  )
  useEffect(() => {
    if (permission?.expirationDate) {
      setExpirationDate(new Date(permission?.expirationDate))
    }
  }, [permission?.expirationDate])
  const targetUrl = useMemo(() => permission?.shortUrl?.targetUrl, [
    permission?.shortUrl
  ])

  const [error, setError] = useState<string | undefined>()

  const { showToast } = useToast()

  const handleDate = (value?: Date | null | undefined) => {
    if (value) {
      setExpirationDate(value)
    } else {
      setExpirationDate(undefined)
    }
  }

  useEffect(() => {
    if (error) {
      showToast(error, ToastType.ERROR)
    }
  }, [error])

  const handleError = (error: string) => {
    setError(error)
  }

  const resetForm = () => {
    setEmailAddress(undefined)
    setFirstName(undefined)
    setLastName(undefined)
    setCommunityId(undefined)
    setExpirationDate(getDefaultDate())
  }

  const handleSubmit = async () => {
    setError(undefined)
    if (
      emailAddress &&
      communityId &&
      expirationDate &&
      firstName &&
      lastName
    ) {
      try {
        await upsertPermission({
          id: permission?.id,
          emailAddress,
          communityId,
          expirationDate,
          firstName,
          lastName
        })
        resetForm()
      } catch (error) {
        handleError('Error saving the permission')
      }
    } else {
      handleError('Please fill all the data in the form.')
    }
  }

  const communitiesOptions: DropdownOption[] = communities.map(community => ({
    label: community.name,
    value: community.id
  }))

  return (
    <Modal
      open={isOn}
      close={() => {
        resetForm()
        turnOff()
      }}
      showCloseIcon
    >
      <Flex>
        <TitleContainer>
          <H4>{'Microsite Permission'}</H4>
        </TitleContainer>
        <Field
          label={'First Name'}
          placeholder={'First Name'}
          value={firstName}
          onChange={setFirstName}
          autoFocus
        />

        <Field
          label={'Last Name'}
          placeholder={'Last Name'}
          value={lastName}
          onChange={setLastName}
        />

        <Field
          label={'User Email'}
          placeholder={'Enter the user email'}
          value={emailAddress}
          onChange={setEmailAddress}
        />

        <TextInputTitle>{'Community'}</TextInputTitle>
        <Dropdown
          options={communitiesOptions}
          placeholder={loadingCommunities ? 'loading...' : 'Communities...'}
          menuStyle={{ maxWidth: 200 }}
          style={{
            backgroundColor: 'transparent',

            width: '100%'
          }}
          closeMenuOnSelect
          onInputChange={setCommunityId}
          value={communityId ?? null}
          onSelect={el => {
            setCommunityId(el)
          }}
          isDisabled={loadingCommunities}
        />
        <TextInputTitle>{'Expiration Date'}</TextInputTitle>
        <DateTimeInput
          value={convertToZondedTime(expirationDate)}
          onChangeDate={handleDate}
          initialDate={getDefaultDate()}
        />

        <Flex>
          <Field
            label={'Url'}
            placeholder={'Your Url'}
            value={targetUrl}
            onChange={() => {}}
            editable={false}
            autoFocus
          />
        </Flex>

        <Button
          disabled={loading || loadingCommunities}
          onPress={handleSubmit}
          label={permission?.id ? 'Edit Permission' : 'Create Permission'}
        />
      </Flex>
    </Modal>
  )
}

export default NewPermissionModal
