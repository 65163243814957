import { EditorState } from 'draft-js'
import { ContentValue } from '../../components/common/form/ContentDrop'
import { MediaUploaderValue } from '../media/components/MediaUploader'
import { Person } from '../../types'

export interface IPageSection {
  uid?: string
  title?: string
  live?: boolean
  exportToPdf: boolean
  includeAttendeesInPdf: boolean
  blocks: IContentBlock[]
}

export interface IContentBlock {
  uid?: string
  live?: boolean
  type?: string
  columns: IBlockColumn[]
}

export interface IBlockColumn {
  type: ColumnType
  subType?: string
  content: Record<string | number | symbol, any>
}

export interface IPageContent {
  id: string
  sections: IPageSection[]
  pdfCloudinaryId?: string
}

export interface IEventAddressResult {
  id: string
  type: 'venue' | 'hotel' | 'home' | 'restaurant'
  description: string
  phone: string
  address: {
    id: string
    name: string
    address1: string
    address2: string
    city: string
    state: string
    country: string
    zip: string
  }
  addressNotes: string
}

export interface IEventResult {
  id: string
  name: string
  nameInternal: string
  salesforceId: string
  eventAddresses: IEventAddressResult[]
  reservationInfo: string
  description: string
  recommendedTransportationName: string
  recommendedTransportationInfo: string
  attendees: Person[]
}

export interface ISectionState {
  uid?:
    | 'logistics'
    | 'header'
    | 'program-guests'
    | 'agenda'
    | 'pageBreak'
    | string
  title?: string
  live?: boolean
  exportToPdf: boolean
  blocks: IBlockState[]
  includeAttendeesInPdf?: boolean
}

export interface IBlockColumnState {
  type: ColumnStateType
  state: InternalColumnState
  subType?: string
}

export type MediaPosition = 'left' | 'background' | 'right'
export type ImageShape = 'rectangle' | 'circle'
export interface IMediaState {
  value: MediaUploaderValue | ContentValue
  position: MediaPosition
  shape: ImageShape
}

export interface ProgramGuestState {
  id: string
  name: { first: string; last: string }
  salesforceId: string
  photo: {
    fileName: string
    cloudinaryId: string
    cropping: string
  }
  bio: EditorState
  title: string
  guestType: 'moderator' | 'speaker'
}

export type InternalColumnState =
  | EditorState
  | ITextColumnValue
  | IMediaState
  | ProgramGuestState
  | null

export interface ITextColumnValue {
  text: string
}

export interface IBlockState {
  uid?:
    | 'hero-image'
    | 'title'
    | 'intro-letter'
    | 'confidentiality'
    | 'overview'
    | string
  type?: 'divider' | 'container' | 'program-guest' | 'text-block' | 'pageBreak'
  live?: boolean
  exportToPdf: boolean
  columns: IBlockColumnState[]
}

export interface IEditorState {
  sections: ISectionState[]
}

export enum ColumnStateType {
  Image = 'image',
  Media = 'media',
  Markdown = 'markdown',
  Text = 'text',
  Custom = 'custom'
}

export enum ColumnType {
  Image = 'image',
  Markdown = 'markdown',
  Text = 'text',
  Video = 'video',
  Audio = 'audio',
  Custom = 'custom'
}
