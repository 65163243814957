import React from 'react'
import { FormikProps } from 'formik'
import { Flex } from '../../../../components/FlexBox'
import { ReorderBlockControls } from '../../ReorderControls'
import {
  ColumnStateType,
  IBlockState,
  IEditorState,
  IMediaState,
  ISectionState
} from '../../types'
import { Switch } from '../../../../components/common/Switch'
import { TouchableOpacity } from 'react-native-gesture-handler'
import DismissIcon from '../../../../components/icon/DismissIcon'
import { View } from 'react-native'
import { Text } from '../../../../components/common/Text'
import CheckBox from '../../../../components/common/CheckBox'
import { createEmptyRichTextEditorState } from '../../../../components/RichTextEditor'
import useTranslation from '../../../../hooks/useTranslation'
import { useTheme } from 'styled-components/native'
import { lighten } from 'polished'

interface IProps {
  block: IBlockState
  blockIndex: number
  section: ISectionState
  sectionIndex: number
  form: FormikProps<IEditorState>
  showLiveInApp?: boolean
}

interface IBlockHeaderProps {
  block: IBlockState
}

function isSingleColumn(
  block: IBlockState,
  context: { section: ISectionState; sectionIndex: number }
) {
  const singleColumnBlockTypes = ['divider', 'container', 'text-block']
  if (block.uid === 'overview' || context.section.uid === 'program-guests') {
    return false
  }
  if (block.type && singleColumnBlockTypes.includes(block.type)) {
    return false
  }
  return true
}

const BlockHeader: React.FC<IBlockHeaderProps> = ({ block }) => {
  const { t } = useTranslation()

  switch (block.uid) {
    case 'confidentiality':
      return <Text>{t('summits:editor:blocks:confidentialityStatement')}</Text>
    case 'hero-image':
      return <Text>{t('summits:editor:blocks:heroImage')}</Text>
    case 'title':
      return <Text>{t('summits:editor:blocks:pageTitle')}</Text>
    case 'overview':
      return <Text>{t('summits:editor:blocks:overview')}</Text>
  }
  switch (block.type) {
    case 'divider':
      return <Text>{t('summits:editor:blocks:divider')}</Text>
    case 'container':
      return <Text>{t('summits:editor:blocks:container')}</Text>
    case 'program-guest':
      return <Text>{t('summits:editor:blocks:programGuest')}</Text>
    case 'text-block':
      return <Text>{t('summits:editor:blocks:textBlock')}</Text>
    case 'pageBreak':
      return <Text>{t('summits:editor:blocks:pageBreak')}</Text>
  }
  return <Text>{t('summits:editor:blocks:contentBlock')}</Text>
}

export const BlocksController: React.FC<IProps> = ({
  block,
  blockIndex,
  section,
  sectionIndex,
  form,
  showLiveInApp = true
}) => {
  const numberOfMarkdownCols = block.columns.filter(
    x => x.type === ColumnStateType.Markdown
  ).length

  const { t } = useTranslation()
  const { colors, sizes } = useTheme()

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="right"
      style={{ marginBottom: sizes[2] }}
    >
      <View
        style={{
          marginRight: 'auto',
          paddingVertical: sizes[1],
          paddingHorizontal: sizes[2],
          borderRadius: sizes[1],
          backgroundColor: lighten(0.3, colors.brightPrimary)
        }}
      >
        <BlockHeader block={block} />
      </View>
      {sectionIndex !== 0 && (
        <>
          <ReorderBlockControls
            blockIndex={blockIndex}
            section={section}
            sectionIndex={sectionIndex}
            form={form}
          />
          {showLiveInApp ? (
            <>
              <Text>{t('summits:editor:showInApp')}:</Text>
              <Switch
                value={block.live}
                onValueChange={x => {
                  form.setFieldValue(
                    `sections.${sectionIndex}.blocks.${blockIndex}.live`,
                    x
                  )
                }}
              />
            </>
          ) : null}

          {isSingleColumn(block, { section, sectionIndex }) && (
            <CheckBox
              title={t('summits:editor:dualColumn')}
              checked={numberOfMarkdownCols === 2}
              disabled={Boolean(
                (block.columns[0]?.state as IMediaState)?.value?.url
              )}
              onPress={() => {
                if (numberOfMarkdownCols === 1) {
                  block.columns.push({
                    type: ColumnStateType.Markdown,
                    state: createEmptyRichTextEditorState()
                  })
                } else {
                  block.columns.pop()
                }
                form.setValues(form.values)
              }}
            />
          )}
          <TouchableOpacity
            onPress={() => {
              section.blocks.splice(blockIndex, 1)
              form.setFieldTouched(
                `sections.${sectionIndex}.blocks.${blockIndex}`,
                false
              )
              form.setValues(form.values)
            }}
            // @ts-expect-error
            style={{ cursor: 'pointer' }}
          >
            <DismissIcon />
          </TouchableOpacity>
        </>
      )}
    </Flex>
  )
}
